/**
 * Note - this is the only reducer where we aren't following
 * the convention of ApiClient returning action.payload
 * This is because these are called in the SSR code path
 * @todo refactor this to be more consistent
 */
import { Map, List, fromJS } from "immutable";
import { pick } from "lodash";
import sha256 from "js-sha256";
import { CLEAR_FAVOURITES_DATA, CLEAR_LOGIN_DATA, ERROR_ACTIVE_FAVOURITES_DATA, ERROR_PAST_FAVOURITES_DATA, FAVOURITE_EVENT, FOLLOW_PARTNER, RECEIVE_ACTIVE_FAVOURITES_DATA, RECEIVE_BTG_DATA, RECEIVE_LOGIN_DATA, RECEIVE_PAST_FAVOURITES_DATA, REQUEST_ACTIVE_FAVOURITES_DATA, REQUEST_PAST_FAVOURITES_DATA, UNFAVOURITE_EVENT, UNFOLLOW_PARTNER, ADD_TO_RECENTLY_VIEWED, REHYDRATE_RECENTLY_VIEWED, ADD_REMINDER, REHYDRATE_REMINDERS, RECEIVE_USER_TOKEN, SET_REPORT_EVENT_DATA, REHYDRATE_REPORT_EVENT_DATA, CLEAR_REPORT_EVENT_DATA, ERROR_LOGIN_DATA } from "../actions/index";
import { setApiKey, setApiSecret, setEncryptedSsoToken } from "../helpers/auth";
import { checkValidEvent } from "../helpers/misc/recentlyViewedHelpers";
const initialState = new Map({
    buyTogetherEvents: new Map(),
    dataReceived: false,
    dataErrored: false,
    isLoggedIn: false,
    isProfileComplete: false,
    favourites: new Map(),
    following: new List([]),
    isBetaUser: false,
    profile: new Map(),
    // this should ideally be an OrderedSet,
    // but we can't serialize and deserialze
    // OrderedSets using JSON
    recentlyViewed: Map({
        movies: List(),
        events: List(),
        isRehydrated: false,
        masterList: Map({})
    }),
    reminders: Map({
        isRehydrated: false,
        remindToPhone: null,
        events: List()
    }),
    reportEventData: Map({
        eventName: "",
        eventURL: "",
        isRehydrated: false
    })
});
const isFollowing = user => {
    if (!user.get("followed"))
        return new List([]);
    const following = {};
    user.get("followed").forEach(c => {
        following[c.get("resource_type")] = c.get("resources").map(_ => _.get("_id"));
    });
    return fromJS(following);
};
// Whitelisted email domains. We display extra debug information for these in our
// apps.
// TODO: find a better way to store these.
const whiteListedEmails = () => ["@insider.in", "@paytm.com", "@paytmmall.com"];
// Hashed phone numbers to be whitelisted. We display extra debug information for these in our
// apps.
const whiteListedNumbers = () => [
    "15177d848bf61c6d49ec4d3af6dab69b32634672e01f08c758725b7c873208c1",
    "aa713c36ed88eb7fe290c96435737b873f982270730d7f1b5eaefaffe63c3e1d",
    "2b87b1bb807a896b13bf6237a666c44d9e2600c5aa26f57c798bd5dcdcddf004",
    "3e676f5e6344ff84e24e5ae31259b6ff9a13d70b54160022f34fe74c97322999" // Anu
];
const isWhiteListedEmail = email => {
    if (!email)
        return false;
    const isEmailHaveWhiteListedExtension = whiteListedEmails().find(whiteListedEmail => {
        return email.endsWith(whiteListedEmail);
    });
    return !!isEmailHaveWhiteListedExtension;
};
const isWhiteListedNumber = phoneNumber => {
    if (!phoneNumber)
        return false;
    return whiteListedNumbers().includes(sha256(phoneNumber));
};
export const isBetaUser = (isLoggedIn, phoneNumber, email) => {
    if (!isLoggedIn)
        return false;
    return isWhiteListedNumber(phoneNumber) || isWhiteListedEmail(email);
};
const checkIfBetaUser = user => {
    const isLoggedIn = !!user.size;
    const email = user.get("email");
    const phone = user.get("phone_no");
    return isBetaUser(isLoggedIn, phone, email);
};
const isProfileComplete = user => {
    if (!user.get("additional_info"))
        return false;
    return user
        .get("additional_info")
        .filter(profileField => profileField.get("name") !== "location")
        .every(input => input.get("is_required", false) === false || input.has("input_value"));
};
const userData = (state = initialState, action) => {
    switch (action.type) {
        case CLEAR_FAVOURITES_DATA: {
            return state.setIn(["favourites", new Map()]);
        }
        case CLEAR_LOGIN_DATA: {
            return initialState;
        }
        case ERROR_ACTIVE_FAVOURITES_DATA: {
            return state.setIn("requestingActiveFavouritesData", false);
        }
        case ERROR_PAST_FAVOURITES_DATA: {
            return state.setIn("requestingPastFavouritesData", false);
        }
        case FAVOURITE_EVENT: {
            const { event } = action.payload;
            // if favourites list exists in the state
            if (state.getIn(["favourites", "slugs"])) {
                // push the current event slug
                state = state.updateIn(["favourites", "slugs"], list => list.push(event.get("slug")));
            }
            // instantiate a new list
            if (!state.getIn(["favourites", "slugs"])) {
                state = state.setIn(["favourites", "slugs"], new List([event.get("slug")]));
            }
            // push the current event
            if (state.getIn(["favourites", "active"])) {
                state = state.updateIn(["favourites", "active"], list => list.push(event));
            }
            // instantiate new list
            if (!state.getIn(["favourites", "active"])) {
                state = state.setIn(["favourites", "active"], new List([event]));
            }
            return state;
        }
        case FOLLOW_PARTNER: {
            const { _id, type } = action.payload;
            if (state.getIn(["following", type])) {
                return state.updateIn(["following", type], list => list.push(_id));
            }
            return state.setIn(["following", type], new List([_id]));
        }
        case RECEIVE_ACTIVE_FAVOURITES_DATA: {
            const fetchedFavourites = fromJS(action.payload), fetchedSlugs = fetchedFavourites.map(e => e.get("slug")), currentSlugs = state.getIn(["favourites", "slugs"]) || new List([]), 
            // concatenate the two list of favourites and remove duplicates by converting to a set and back
            finalSlugs = currentSlugs
                .concat(fetchedSlugs)
                .toSet()
                .toList();
            return state.withMutations(s => s
                .set("requestingActiveFavouritesData", false)
                .setIn(["favourites", "slugs"], finalSlugs)
                .setIn(["favourites", "active"], fetchedFavourites));
        }
        case RECEIVE_BTG_DATA: {
            return state.withMutations(s => {
                s.setIn(["buyTogetherEvents", action.payload.get("event_slug")], action.payload);
                return s;
            });
        }
        case RECEIVE_LOGIN_DATA: {
            const apiKey = action.payload.get("apiKey");
            const secret = action.payload.get("secret");
            const encryptedSsoToken = action.payload.get("encryptedSsoToken");
            if (encryptedSsoToken) {
                setEncryptedSsoToken(encryptedSsoToken);
            }
            if (apiKey && secret) {
                setApiKey(apiKey);
                setApiSecret(secret);
            }
            const payload = action.payload.delete("secret");
            if (window.ga) {
                const userId = payload.get("_id");
                window.ga("set", "userId", userId);
                window.ga("send", "event", "authentication", "user-id available", { nonInteraction: 1 });
            }
            return state.withMutations(s => s
                .set("dataReceived", true)
                .set("profile", payload)
                .set("isLoggedIn", !!payload.size)
                .set("isProfileComplete", isProfileComplete(payload))
                .set("following", isFollowing(payload))
                .set("isBetaUser", checkIfBetaUser(payload))
                .deleteIn(["profile", "followed"])
                .set("dataErrored", false));
        }
        case ERROR_LOGIN_DATA: {
            return state.set("dataErrored", true);
        }
        case RECEIVE_PAST_FAVOURITES_DATA: {
            const fetchedFavourites = fromJS(action.payload), fetchedSlugs = fetchedFavourites.map(e => e.get("slug")), currentSlugs = state.getIn(["favourites", "slugs"]) || new List([]), 
            // concatenate the two list of favourites and remove duplicates by converting to a set and back
            finalSlugs = currentSlugs
                .concat(fetchedSlugs)
                .toSet()
                .toList();
            return state.withMutations(s => s
                .set("requestingPastFavouritesData", false)
                .setIn(["favourites", "slugs"], finalSlugs)
                .setIn(["favourites", "past"], fetchedFavourites));
        }
        case REQUEST_ACTIVE_FAVOURITES_DATA: {
            return state.setIn(["requestingActiveFavouritesData"], true);
        }
        case REQUEST_PAST_FAVOURITES_DATA: {
            return state.setIn(["requestingPastFavouritesData"], true);
        }
        case UNFAVOURITE_EVENT: {
            const { slug } = action.payload, favouriteSlugs = state.getIn(["favourites", "slugs"]) || new List(), activeFavourites = state.getIn(["favourites", "active"]) || new List(), pastFavourites = state.getIn(["favourites", "past"]) || new List(), index = favouriteSlugs.findIndex(s => s === slug), activeIndex = activeFavourites.findIndex(s => s.getIn(["slug"]) === slug), pastIndex = pastFavourites.findIndex(s => s.getIn(["slug"]) === slug);
            if (index > -1) {
                favouriteSlugs.size && (state = state.deleteIn(["favourites", "slugs", index]));
            }
            if (activeIndex > -1) {
                activeFavourites.size && (state = state.deleteIn(["favourites", "active", activeIndex]));
            }
            if (pastIndex > -1) {
                pastFavourites.size && (state = state.deleteIn(["favourites", "past", pastIndex]));
            }
            return state;
        }
        case UNFOLLOW_PARTNER: {
            const { _id, type } = action.payload;
            const index = state.getIn(["following", type]).findIndex(id => id === _id);
            return state.deleteIn(["following", type, index]);
        }
        case ADD_TO_RECENTLY_VIEWED: {
            const { key, eventData, type } = action.payload;
            if (type !== "events" && type !== "movies") {
                // defensive short circuit
                return state;
            }
            // do set-y operations here
            const staleRecentlyViewed = state.getIn(["recentlyViewed", type]);
            const hasAlreadViewedIndex = staleRecentlyViewed.findIndex(v => v === key);
            if (hasAlreadViewedIndex !== -1) {
                const updatedRecentlyViewed = staleRecentlyViewed
                    // remove old entry
                    .delete(hasAlreadViewedIndex)
                    // move to top of list
                    .insert(0, key);
                return state.setIn(["recentlyViewed", type], updatedRecentlyViewed);
            }
            // Extract only required keys from eventData to be pushed into localStorage
            const eventDataJS = eventData.toJS();
            const partialEventData = pick(eventDataJS, [
                "horizontal_cover_image",
                "event_type",
                "slug",
                "name",
                "max_show_end_utc_timestamp",
                "category_id",
                "price_display_string",
                "city"
            ]);
            if (eventDataJS.venue) {
                partialEventData.venue_date_string = eventDataJS.venue.date_string;
                partialEventData.venue_name = eventDataJS.venue.name;
            }
            // not already in list, add at the top
            return state
                .updateIn(["recentlyViewed", type], v => v.insert(0, key))
                .setIn(["recentlyViewed", "masterList", key], fromJS(partialEventData));
        }
        case REHYDRATE_RECENTLY_VIEWED: {
            const recentlyViewedFromCache = fromJS(action.payload);
            if (!recentlyViewedFromCache)
                return state;
            let typeKey = "events";
            // do we already have some recently viewed?
            const prevRecentlyViewed = recentlyViewedFromCache.get(typeKey);
            const currRecentlyViewed = state.getIn(["recentlyViewed", typeKey]);
            const prevMasterList = recentlyViewedFromCache.get("masterList");
            const cleanedPrevMasterList = prevMasterList.filter(event => checkValidEvent(event));
            const cleanedpPevRecentlyViewed = prevRecentlyViewed.filter(eventId => cleanedPrevMasterList.has(eventId));
            const currentMasterList = state.getIn(["recentlyViewed", "masterList"]);
            if (currRecentlyViewed.size === 0) {
                return state.withMutations(s => s
                    .setIn(["recentlyViewed", typeKey], cleanedpPevRecentlyViewed)
                    .setIn(["recentlyViewed", "isRehydrated"], true)
                    .setIn(["recentlyViewed", "masterList"], cleanedPrevMasterList));
            }
            // if we do, they take precendence
            // so the simple operation here would be to do
            // an append, where the local cache is just appeneded
            // to whatever entries exist in memory
            // However, since we are using an OrderedSet construct
            // over this List, we have to be careful that we don't
            // duplicate values
            // We do this by seeing if any values in the current list
            // also exist in prev list, if they do, then remove from
            // prev list. We can do this because the order they are
            // present in the current list is the valid order, since that
            // is more recent user browsing behaviour
            // eg - event id '1234' exists in 2nd position in cached
            // recently viewed, but exists in first position in the curr
            // list. Therefore, in the final merged list it should continue
            // to be in the first position
            const updatedPrevList = prevRecentlyViewed.reduce((acc, value) => {
                if (currRecentlyViewed.findIndex(v => v === value) > -1) {
                    // this is already in current list
                    // ignore it
                    return acc;
                }
                return acc.push(value);
            }, List());
            const mergedList = currRecentlyViewed.concat(updatedPrevList);
            const mergedMasterList = cleanedPrevMasterList.merge(currentMasterList);
            return state.withMutations(s => s
                .setIn(["recentlyViewed", typeKey], mergedList)
                .setIn(["recentlyViewed", "isRehydrated"], true)
                .setIn(["recentlyViewed", "masterList"], mergedMasterList));
        }
        case ADD_REMINDER: {
            let { remindToPhone, eventSlug, reminderType } = action.payload;
            return state.withMutations(s => s
                .updateIn(["reminders", reminderType], v => v.push(eventSlug))
                .setIn(["reminders", "remindToPhone"], remindToPhone));
        }
        case REHYDRATE_REMINDERS: {
            const reminderData = fromJS(action.payload);
            if (!reminderData)
                return state;
            const typeKey = "events";
            const prevReminders = reminderData.get(typeKey);
            const prevRemindToPhone = reminderData.get("remindToPhone");
            const currReminders = state.getIn(["reminders", typeKey]);
            const curreRemindToPhone = state.getIn(["reminders", "remindToPhone"]);
            if (currReminders.size === 0) {
                return state.withMutations(s => s
                    .setIn(["reminders", typeKey], prevReminders)
                    .setIn(["reminders", "isRehydrated"], true)
                    .setIn(["reminders", "remindToPhone"], prevRemindToPhone));
            }
            let updatedPrevReminders = prevReminders.filter(eventSlug => !currReminders.includes(eventSlug));
            let mergedReminders = currReminders.concat(updatedPrevReminders);
            return state.withMutations(s => s
                .setIn(["reminders", typeKey], mergedReminders)
                .setIn(["reminders", "isRehydrated"], true)
                .setIn(["reminders", "remindToPhone"], curreRemindToPhone));
        }
        case RECEIVE_USER_TOKEN: {
            const data = fromJS(action.payload);
            const token = data.get("token");
            return state.withMutations(s => s.setIn(["profile", "user_token"], token));
        }
        case SET_REPORT_EVENT_DATA: {
            return state.withMutations(state => {
                state.setIn(["reportEventData", "eventName"], action.payload.eventName);
                state.setIn(["reportEventData", "eventURL"], action.payload.eventURL);
                return state;
                ;
            });
        }
        case REHYDRATE_REPORT_EVENT_DATA: {
            return state.withMutations(state => {
                state.setIn(["reportEventData", "eventName"], action.payload.eventName);
                state.setIn(["reportEventData", "eventURL"], action.payload.eventURL);
                state.setIn(["reportEventData", "isRehydrated"], true);
                return state;
                ;
            });
        }
        case CLEAR_REPORT_EVENT_DATA: {
            return state.withMutations(state => {
                state.setIn(["reportEventData", "eventName"], "");
                state.setIn(["reportEventData", "eventURL"], "");
                return state;
                ;
            });
        }
        default:
            return state;
    }
};
export default userData;
