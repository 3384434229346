/** @jsx jsx */
// import PropTypes from "prop-types";
import React from "react";
import classNames from "classnames";
import { css, jsx } from "@emotion/core";
import { colors, mediaFeatures } from "../../styles/common";
import cookies from "browser-cookies";
import { sendClevertapEvent } from "../../helpers/misc";
import { CLEVERTAP_ACTIONS } from "../../helpers/constants";
import { ArrowDownIcon } from "../ui/icons/ArrowDownIcon";
import { ArrowUpIcon } from "../ui/icons/ArrowUpIcon";
import CitySelectorSearch from "./CitySelectorSearch";
import { CityLocationIcon } from "../ui/icons/CityLocationIcon";
import CitySelectorListItem from "./CitySelectorItem";
import { DATA_REFS } from "../../helpers/constants/dataRefs";
import { LocationIcon } from "../ui/icons/LocationIcon";
import { backgroundColor, borderColor } from "./AppBar/style";
import { generateLinkForAllEvents } from "../../helpers/linkGenerators";
import Media from "react-media";
import { OnlinePlayIcon } from "../ui/icons/OnlinePlayIcon";
import { ChevronRight } from "../ui/icons/ChevronRight";
import { connect } from "react-redux";
import { setIsChangedCity } from "../../actions/index";
const AlternateCityWrapper = `
	display: block;
	width: 90%;
	position: relative;
`;
const AlternateCityButtonStyles = `
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	font-size: 18px;
	height: 56px;
	color: ${colors["slight-light-gray"]};
	border-radius: 6px;
	background-color: ${colors.white};
	padding: 10px;
	cursor: pointer;
	white-space: nowrap;
	box-shadow: 1px 1px 10px;
	&::hover {
		background-color: #eeeeee;
	}
	@media screen and ${mediaFeatures["for-mobile-and-tablet"]} {
		font-size: 14px;
	}
	@media screen and ${mediaFeatures["for-mobile"]} {
		padding: 10px;
	}
`;
const AlternateCityTitleWrapper = `
	display: flex;
	align-items: center;
	justify-content: flex-start;
`;
const AlternateCityLabel = `
	margin-left: 10px;
`;
const AlternateCityCheckboxStyles = `
	display: none;
`;
const CitySelectorStyles = (activeColor) => `
	display: flex;
	align-items: center;
	padding: 7px 12px;
	height: 38px;
	margin: 0 15px;
	background: ${activeColor ? backgroundColor[activeColor] : "transparent"};
	border-radius: 100px;
	color: white;
	font-weight: 600;
	font-size: 13px;
	line-height: 150%;
	text-transform: Capitalize;
	cursor: pointer;
	border: 1px solid ${activeColor ? borderColor[activeColor] : colors["border-grey"]};
    transition: box-shadow .3s;

	@media screen and (max-width: 525px) {
		margin: 0 8px;
		padding: 7px;
	}

    &:hover {
		border: 1px solid ${activeColor ? borderColor[activeColor] : "white"};
	}

	&::after {
		display: none;
		top: 22px;
		right: 15%;
		margin-left: -16px;
		z-index: 120;
		content: " ";
		position: relative;
		width: 0;
		height: 0;
		border-left: 8px solid transparent;
		border-right: 8px solid transparent;
		border-top: 8px solid transparent;
		border-bottom: 8px solid #eee;

		@media screen and (min-width: 576px) {
			right: 40%;
		}
	}
	&.active {
		&::after {
			display: block;
		}
		.city-dropdown {
			transform: scale(1, 1);
		}
	}
`;
const CitySelectorButton = `
	display: flex;
	align-items: center;
`;
const CityDropdownStyles = `
	position: absolute;
	top: 60px;
	right: 0;
	background: ${colors.white};
	color: ${colors.black};
	box-shadow: 0 3px 10px rgba(51, 51, 51, 0.2);
	border-radius: 2px;
	white-space: nowrap;
	display: block;
	z-index: 120;
	transition: all 0.1s ease-in-out;
	transform-origin: 60% 0;
	width: 245px;
	overflow-y: hidden;
	& ul {
		padding: 0 12px 12px;
	}

	@media screen and (max-width: 768px) {
		top: 56px;
	}
`;
const AlternateCityDropdownStyles = `
	${CityDropdownStyles}
	top: 0;
	right: auto;
	width: 100%;
	max-height: unset;
	padding-bottom: 6px;
	border-radius: 6px;
`;
const NotFoundStyle = `
	display: block;
	padding: 12px 12px;
	color: ${colors["slight-light-gray"]};
`;
const CityListEachStyles = `
	font-weight: 400;
	list-style: none;
	max-height: calc(100vh - 180px);
	overflow-y: scroll;
	margin-top: 8px;
`;
const AlternateCityListEachStyles = `
	${CityListEachStyles}
	max-height: 28vh;
	& ul {
		padding: 0 12px 6px 12px;
	}
`;
const CityLinkStyles = `
	padding: 12px;
	text-decoration: none;
	color: inherit;
	display: block;
	transition: all 0.1s ease-in-out;
	&:hover {
		transform: translate(5px);
		color: ${colors.actionBlue};
	}
`;
const AlternateCityLinkStyles = `
	${CityLinkStyles};
	padding: 9px !important;
`;
const FirstTimeOverlayStyles = `
	cursor: default;
	position: fixed;
	background: rgba(0, 0, 0, 0.8);
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	z-index: 119;
	display: block;
`;
const FirstTimeIntroStyles = `
	position: fixed;
	width: 60%;
	min-width: 500px;
	top: 50%;
	left: 50%;
	transform: translate(-45%, -60%);
`;
const LocationIconStyle = `
	@media screen and (min-width: 526px) {
		margin-right: 6px;
	}
`;
const HideCitySelectorLabel = `
	@media screen and (max-width: 525px) {
		display: none;
	}
`;
const BrowseOnlineEvents = `
	display: flex;
	padding: 8px 6px;
	align-items: center;
	gap: 6px;
	border-radius: 12px;
	border: 1px solid ${colors.faintGrey};
	background: white;
	margin: 8px 8px 0;
	color: ${colors.descriptionColor};
    font-size: 13px;
    font-weight: 600;
    line-height: 150%;
`;
const addShowFirstTimeBannerToCache = () => {
    cookies.set("showFirstTimeBanner", "true", { expires: 1 });
};
class CitySelector extends React.Component {
    constructor() {
        super(...arguments);
        this.state = {
            showing: false,
            isCitySelectorOpen: this.props.shouldShowCitySelector,
            isAlternateCitySelectorOpen: false,
            searchText: ""
        };
        this.handleCitySelect = (citySelected, source_page) => {
            if (this.props.isAlternateCitySelector) {
                sendClevertapEvent(CLEVERTAP_ACTIONS.CITY_SELECTION_OVERLAY, {
                    selected_city: citySelected,
                    source_page: source_page
                });
            }
            else {
                const pagePathName = (window.location && window.location.pathname);
                sendClevertapEvent(CLEVERTAP_ACTIONS.CITY_SELECTION, {
                    selected_city: citySelected,
                    current_page: pagePathName || ""
                });
            }
        };
        this.searchHandler = event => {
            const searchText = event.target.value.trim();
            this.setState({
                searchText: searchText
            });
        };
        this.filterCities = city => city
            .get("name")
            .toLowerCase()
            .includes(this.state.searchText.toLowerCase());
        this.toggleCitySelectorOpen = () => {
            this.setState(prevState => {
                return Object.assign(Object.assign({}, prevState), { isAlternateCitySelectorOpen: !prevState.isAlternateCitySelectorOpen });
            });
        };
        this.getActiveColor = (selectedCity) => {
            const currentPath = this.props.location.pathname + this.props.location.search;
            if (!selectedCity || currentPath === generateLinkForAllEvents(selectedCity)) {
                return undefined;
            }
            if (selectedCity.toLowerCase() === "online") {
                return "pink";
            }
            else {
                return "blue";
            }
        };
    }
    componentDidMount() {
        if (this.props.shouldShowCitySelector && this.state.showing) {
            document.documentElement.classList.add("no-scroll");
        }
        this.setState(() => {
            return {
                showing: true
            };
        });
    }
    componentWillReceiveProps(nextProps) {
        if (nextProps.shouldShowCitySelector !== this.props.shouldShowCitySelector) {
            this.setState({
                isCitySelectorOpen: nextProps.shouldShowCitySelector
            });
        }
    }
    render() {
        const cityLinkStylesToApply = this.props.isAlternateCitySelector ? AlternateCityLinkStyles : CityLinkStyles;
        const pinnedCities = this.props.cities
            .get(true)
            .filter(this.filterCities)
            .map(city => (<CitySelectorListItem handleCitySelect={this.handleCitySelect} city={city} cityLinkStylesToApply={cityLinkStylesToApply} isAlternateCitySelector={this.props.isAlternateCitySelector}/>));
        const otherCities = this.props.cities
            .get(false)
            .filter(this.filterCities)
            .map(city => (<CitySelectorListItem handleCitySelect={this.handleCitySelect} city={city} cityLinkStylesToApply={cityLinkStylesToApply} isAlternateCitySelector={this.props.isAlternateCitySelector}/>));
        const markCityChange = this.props.markCityChange;
        const notFound = pinnedCities.size + otherCities.size === 0;
        const isCitySelectorActive = this.state.isCitySelectorOpen && this.state.showing;
        const CityLabelFragment = (<div css={css(HideCitySelectorLabel)}>
				<span>{this.props.selectedCity ? this.props.selectedCity : "All Cities"}</span>
				{this.state.isCitySelectorOpen ? (<ArrowUpIcon fillColor="white" overideStyle={{ marginLeft: "6px" }}/>) : (<ArrowDownIcon fillColor="white" overideStyle={{ marginLeft: "6px" }}/>)}
			</div>);
        return !this.props.isAlternateCitySelector ? (<li id="city-selector" css={css(CitySelectorStyles(this.getActiveColor(this.props.selectedCity)))} className={classNames({
                active: isCitySelectorActive
            })} onClick={() => {
                // if we have force city selector
                // then don't toggle dropdown
                if (this.state.isCitySelectorOpen && this.props.shouldShowCitySelector)
                    return;
                this.setState(prev => (Object.assign(Object.assign({}, prev), { isCitySelectorOpen: !prev.isCitySelectorOpen })));
                addShowFirstTimeBannerToCache();
            }}>
				<div css={css(CitySelectorButton)}>
					<LocationIcon size="24" stroke="white" cssStyle={LocationIconStyle}/>
					<Media query={`screen and (min-width: 525px)`} render={() => CityLabelFragment}/>
				</div>
				<div css={css(CityDropdownStyles)} className="city-dropdown" onClick={() => this.setState({ isCitySelectorOpen: false })}>
					<a onClick={() => {
                window.location.reload();
                markCityChange();
                cookies.set("skip_online_overlay", "true", { secure: true, expires: 7 });
                cookies.set("newCity", "online", { secure: true, expires: 7 });
                cookies.set("cachedCity", "online", { secure: true, expires: 7 });
            }}>
						<div css={css(BrowseOnlineEvents)}>
							<OnlinePlayIcon size="32" fill={colors.primaryPink}/>
							Browse online events
							<ChevronRight fill={colors.primaryPink}/>
						</div>
					</a>
					<CitySelectorSearch searchHandler={this.searchHandler} placeholderContent={"Enter city"} searchText={this.state.searchText}/>
					{/* <CitySelectorCurrentLocation citiesList={this.props.cities.get(true).concat(this.props.cities.get(false))} /> */}
					<ul css={css(CityListEachStyles)}>
						{notFound && <span css={css(NotFoundStyle)}>Not Found</span>}
						{pinnedCities}
						{!this.state.searchText && <hr />}
						{otherCities}
					</ul>
				</div>

				{this.props.shouldShowCitySelector && this.state.showing && (<div css={css(FirstTimeOverlayStyles)}>
						<img css={css(FirstTimeIntroStyles)} src="/assets/images/extras/first-time-greeting.png" alt="Welcome to the new Paytm Insider. Just select your city to begin"/>
					</div>)}
			</li>) : (<label css={css(AlternateCityWrapper)}>
				<div css={css(AlternateCityButtonStyles)} onClick={event => {
                this.toggleCitySelectorOpen();
                addShowFirstTimeBannerToCache();
                event.preventDefault(); // To prevent propagation to input element
            }} data-ref={DATA_REFS.CITY_SELECTOR_OVERLAY_DROPDOWN}>
					<div css={css(AlternateCityTitleWrapper)}>
						<CityLocationIcon />
						{this.props.selectedCity && this.props.selectedCity.toLowerCase() !== "online" ? (this.props.selectedCity) : (<div css={css(AlternateCityLabel)}>Choose your city</div>)}
					</div>
					{this.state.isAlternateCitySelectorOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}
				</div>
				<input type="checkbox" css={css(AlternateCityCheckboxStyles)} id="test"/>
				{this.state.isAlternateCitySelectorOpen ? (<div css={css(AlternateCityDropdownStyles)} className="alternate-city-dropdown">
						<CitySelectorSearch searchHandler={this.searchHandler} placeholderContent={"Enter your city name"} searchText={this.state.searchText}/>
						{/* <CitySelectorCurrentLocation
                    citiesList={this.props.cities.get(true).concat(this.props.cities.get(false))}
                /> */}
						<ul css={css(AlternateCityListEachStyles)}>
							{notFound && <span css={css(NotFoundStyle)}>Not Found</span>}
							{pinnedCities}
							{!this.state.searchText && <hr />}
							{otherCities}
						</ul>
					</div>) : null}
			</label>);
    }
}
const mapDispatchToProps = dispatch => {
    return {
        markCityChange: () => dispatch(setIsChangedCity(true))
    };
};
export default connect(null, mapDispatchToProps)(React.memo(CitySelector));
